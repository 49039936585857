<template>
  <div class="station-alarm-container">
    <div class="alarm-data-query">
      <AuthButton v-for="(cmd, idx) in canDoCmds" :key="idx" :opCode="cmd.opcode" size="default"
        style="margin-right: 5px" @click="clickCmd(cmd)">{{showLang(cmd.lang)}}</AuthButton>
      <Button opCode='sc' type="success" size="default" style="margin-right: 5px"
        @click="sendFailed">{{showLang('com.but.failed.resend')}}</Button>
      <Button opCode='sc' type="success" size="default" style="margin-right: 5px"
        @click="exportResult">{{showLang('com.op.export.results')}}</Button>
      <Checkbox v-model="clearOldLog">{{showLang('com.lamp.is.ins')}}</Checkbox>
      <Checkbox v-model="isScreenSelected">{{showLang('com.lamp.is.screen')}}</Checkbox>
    </div>
    <div class="alarm-data-area" ref="table">
      <vxe-table :empty-text="showLang('com.data.no')" border resizable show-overflow show-header-overflow keep-source
        ref="chkTable" @checkbox-change="selectChangeEvent" :data="devices" :height="tabHeight"
        :row-config="{isHover: true}" :checkbox-config="{checkField: 'checked',}">
        <vxe-column type="checkbox" width="60"></vxe-column>
        <vxe-column type="seq" width="60"></vxe-column>
        <vxe-column field="pname" :title="showLang('com.ins.ch.name')" width="120" sortable header-align="center">
        </vxe-column>
        <vxe-column field="sname" :title="showLang('com.site.name')" width="150" sortable header-align="center">
        </vxe-column>
        <vxe-column field="rate" :title="showLang('com.tab.transformer.num')" width="150" sortable
          header-align="center">
        </vxe-column>
        <vxe-column field="message" :title="showLang('com.ins.result')" header-align="center" sortable></vxe-column>
      </vxe-table>
    </div>
    <ModalArgEdit v-model="showArgModal" :command="cmdItem" @saved="argsSaved" />
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ModalArgEdit from '@/views/light/road/monitor/station/control/ModalArgEdit'
import exportXlsx from '@/components/xlsx/exportXlsx'
export default {
  name: 'CommandEnergyIndex',
  components: {
    ModalArgEdit,
  },
  props: {
  },
  data() {
    return {
      filter: {
        stationId: 0,
        channel: 0,
        timeId: 0,
      },
      timeTables: [],
      clearOldLog: this.clearOldLogs,
      tabHeight: 600,
      showArgModal: false,
      showPlanModal: false,
      showCreateModal: false,
      cmdItem: { item: {}, other: {}, args: {} },
      items: [
        { needArgs: false, needPswd: false, code: 'forceRead', opcode: 'dcs', name: '召测数据', lang: 'com.but.survey', img: this.img.light.road.single.ctrl.forceRead },
        { needArgs: false, needPswd: false, code: 'getclock', opcode: 'dcs', name: '读取时钟', lang: 'com.but.get.read.clock', img: this.img.light.road.single.ctrl.readClock },
        { needArgs: false, needPswd: true, code: 'setclock', opcode: 'dcc', name: '校准时钟', lang: 'com.but.get.Calibrate', img: this.img.light.road.single.ctrl.writeClock },
        { needArgs: true, needPswd: false, code: 'getComArgs', opcode: 'dcs', name: '查询串口参数', lang: 'com.but.get.serial.port', img: this.img.light.road.single.ctrl.info },
        { needArgs: true, needPswd: true, code: 'setComArgs1', opcode: 'dcc', name: '设置串口参数', lang: 'com.but.set.serial.port', img: this.img.light.road.single.ctrl.info },
      ],
      devices: [],
      cmdResults: {},
      timer: null,
      originalDevices: [],
      isScreenSelected: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes', 'showLang']),
    ...mapState('cmd', ['cmdResultRefresh', 'commands', 'windowOnResize', 'clearOldLogs']),
    ...mapState('common', ['deviceTypes', 'productCodes', 'weeks1', 'ampm', 'monitorActionTypes', 'powerTableActions']),
    ...mapState('group', ['selectedDevices', 'selectedNode']),
    canDoCmds: function () {
      let cmds = [];
      for (let cmd of this.items) {
        if (this.funCodes(cmd.opcode)) cmds.push(cmd);
      }
      return cmds;
    }
  },
  watch: {
    clearOldLog() {
      this.$store.commit('cmd/setOldLogStatus', this.clearOldLog)
    },
    isScreenSelected() {
      this.screenSelected();
    },
    windowOnResize() {
      this.setTableHeight();
    },
    'filter.stationId'() {
      this.initList();
    },
    'filter.channel'() {
      this.initList();
    },
    'filter.timeId'() {
      this.initList();
    },
    selectedNode() {
      this.initList();
    },
  },
  mounted: function () {
    this.clearOldLog = this.clearOldLogs;
    setTimeout(this.setTableHeight, 100);
    this.initList();
    this.timer = setInterval(this.setCmdResult, 500);
    window.eventBus.$on('paramCommandComing', params => {
      // console.log('energy cmd data:', params);
      // this.cmdResults[params.cmdId] = params;
      params.time = new Date().getTime();
      this.cmdResults[params.commandId] = params;
    })
    // this.$store.dispatch('common/getAlarmTypes');
  },
  destroyed: function () {
    window.eventBus.$off('paramCommandComing');
    clearInterval(this.timer);
  },
  methods: {
    screenSelected() {
      if (this.isScreenSelected) {
        this.devices = this.devices.filter(p => p.checked);
      } else {
        this.devices = this.originalDevices;
      }
    },
    selectChangeEvent({ rowIndex }) {
      this.$refs.chkTable.reloadRow(this.list, null, rowIndex)
    },
    setCmdResult: function () {
      for (let cmdId in this.cmdResults) {
        let ds = this.devices.filter(p => p.cmdId == cmdId);
        if (ds.length == 0) {
          if(new Date().getTime() - this.cmdResults[cmdId].time > 100000){
            console.log('cmd id time out');
            delete this.cmdResults[cmdId];
          }
          continue;
        } else {
          let item = this.cmdResults[cmdId];
          this.$set(ds[0], 'status', item.status);
          this.$set(ds[0], 'result', item.message);
          this.$set(ds[0], 'content', item.content);
          this.$set(ds[0], 'message', this.getCmdStatus(ds[0]));
          delete this.cmdResults[cmdId]
        }
      }
      this.calcSuccessRate();
    },
    calcSuccessRate: function () {
      let total = this.devices.length;
      let success = this.devices.filter(p => p.status == 9).length;
      let rate = 0;
      if (total <= 0) {
        rate = 0;
      } else {
        rate = (success * 100 / total).toFixed(2);
      }
      window.eventBus.$emit('commandSuccessRateChanged', { total, success, rate });
    },
    exportResult: function () {
      let cmdData = [];
      this.devices.map(item => {
        cmdData.push({
          name: item.name,
          sname: item.sname,
          channel: `K${item.channel}`,
          timeId: item.timeName,
          result: this.getCmdStatus(item)
        })
      });
      let tabHead = {
        name: this.showLang('com.export.cn.devname'),
        sname: this.showLang('com.site.name'),
        type: this.showLang('com.export.cn.type'),
        result: this.showLang('com.ins.result')
      }
      exportXlsx(cmdData, tabHead, this.showLang('com.ins.result'));
    },
    setTableHeight: function () {
      this.tabHeight = this.$refs.table.clientHeight - 5;
    },
    sendFailed: function () {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      let cmds = [];
      let code = '';
      let args = {};
      for (let d of this.devices) {
        if ((d.status == 7 || d.status == 3 || d.status == 8) && chks.filter(p => p.id == d.id).length > 0) {
          this.$set(d, 'status', 0)
          this.$set(d, 'result', this.showLang('com.lighting.repeat.preparation'))
          this.$set(d, 'message', this.getCmdStatus(d))
          // cmds.push({ id: d.id, code: d.cmd, name: d.name, deviceId: d.devId, args: d.args });
          cmds.push(d.id);
          code = d.cmd;
          args = d.args;
        } else if (this.clearOldLog) {
          this.$set(d, 'status', 0)
          this.$set(d, 'result', '')
        }
      }
      let cmd = {
        code: code,
        list: cmds,
        checkUserId: 0,
        args: args
      };
      setTimeout(() => {
        this.sendCommand(cmd);
      }, 500)
    },
    initList: function () {
      let groupId = 0, stationId = 0;
      if (this.selectedNode.type == this.productCodes.station) {
        stationId = this.selectedNode.id;
      } else {
        groupId = this.selectedNode.id;
      }
      this.$axios.post(`//${this.domains.trans}/station/config/QueryEnergy`, { groupId, stationId }).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          let devs = [];
          for (let p of res.data) {
            devs.push({ id: p.id, pname: p.name, devId: p.deviceId, sname: p.stationName, rate: p.content.rate, cmdId: 0, cmd: '', name: '', args: {}, content: {}, time: 0, status: 0, result: '', message: '' })
          }
          this.$set(this, 'originalDevices', devs);
          this.$set(this, 'devices', devs);
          this.calcSuccessRate();
        }
      });
    },
    clickCmd: function (params) {
      let chks = this.$refs.chkTable.getCheckboxRecords();
      if (chks.length == 0) {
        this.$Message.warning(this.showLang('com.control.meter'));
        return;
      }
      if (params.code == 'getYearPlan') {
        let now = new Date().format('yyyy-MM-dd');
        this.cmdItem = { code: params.code, name: params.name, pswd: params.needPswd, args: { single: true, date: now } };
        this.showArgModal = true;
        return;
      } else if (params.code == 'setComArgs1') {
        this.cmdItem = { code: params.code, name: params.name, pswd: params.needPswd, args: {} };
        this.showArgModal = true;
        return;
      } else {
        if (this.clearOldLog) {
          for (let item of this.devices) {
            item.result = '';
            item.status = 0;
          }
        }
        let cmds = []
        let ds = {};
        for (let chk of chks) {
          if (chk.topId == 0) {
            ds[chk.sname] = chk.devId;
          } else {
            ds[chk.sname] = chk.topId;
          }
          // let args = {};
          // if (params.code == 'getWeekControl') {
          //   args = { channel: chk.channel }
          // }
          // chk.result = this.showLang('com.lighting.ready.sending');
          // chk.status = 0;
          // chk.cmd = params.code;
          // chk.name = params.name;
          // chk.args = args;
          // chk.message = this.getCmdStatus(chk);
          // cmds.push({ id: chk.id, code: params.code, name: params.name, deviceId: chk.devId, args: args });
          cmds.push(chk.id);
        }
        let cmd = {
          code: params.code,
          list: cmds,
          checkUserId: 0,
          args: params.args
        };
        this.sendCommand(cmd);
      }
    },
    argsSaved: function (params) {
      if (this.clearOldLog) {
        for (let item of this.devices) {
          item.result = '';
          item.status = 0;
        }
      }
      let chks = this.$refs.chkTable.getCheckboxRecords();
      let cmds = []
      let ds = {};
      for (let chk of chks) {
        ds[chk.sname] = chk.topId;
        // chk.result = '';
        // chk.status = 0;
        // let args = this.clone(params.args);// {channel: chk.channel}
        // args.channel = chk.channel;
        // // params.args.channel = chk.channel;
        // if (params.code == 'handControl') {
        //   args.point = false;
        //   // args.expire = params.args.expire;
        //   // params.args.point = false;
        // } else if (params.code == 'setYearPlan') {
        //   // params.args.timeId = chk.timeId;
        //   args.timeId = chk.timeId;
        // } else if (params.code == 'setWeekControl') {
        //   // params.args.timeId = chk.timeId;
        //   args.timeId = chk.timeId;
        // } else if (params.code == 'setComArgs1') {
        //   params.code = 'setComArgs';
        // }
        // chk.result = this.showLang('com.lighting.ready.sending');
        // chk.status = 0;
        // chk.cmd = params.code;
        // chk.name = params.name;
        // chk.args = args;
        // chk.message = this.getCmdStatus(chk);
        // cmds.push({ id: chk.id, code: params.code, name: params.name, deviceId: chk.devId, paramId: chk.id, args: args });
        cmds.push(chk.id);
      }
      let cmd = {
        code: params.code,
        list: cmds,
        checkUserId: 0,
        args: params.args
      };
      this.sendCommand(cmd);
    },
    getCmdStatus: function (cmd) {
      // console.log('cmd status changed', cmd)
      switch (cmd.status) {
        case 0: return `${cmd.result}`;
        case 2: return this.showLang('cmd.has.sent') + this.showLang('com.ins.dev.reply');
        case 4: return this.showLang('com.ins.execution') + `：${cmd.content.pkg} / ${cmd.content.total}`;
        case 5: return this.showLang('com.ins.fail.retry') + `：${cmd.result}`;
        case 7: return this.showLang('cmd.has.timeout');
        case 8: return this.showLang('com.ins.fail') + `：${cmd.result}`;
        case 9:
          // console.log('getCmdStatus', cmd)
          switch (cmd.cmd) {
            case 'getTimeEnable': {
              let ch = cmd.channel;
              let content = cmd.content[`channel${ch}`];
              let chs = [];
              for (let i = 0; i < 4; i++) {
                chs.push(`${this.showLang('com.date.period')}${i + 1}: ${content[i] ? this.showLang('com.state.enable') : this.showLang('com.state.disable')}`);
              }
              return chs.join(', ');
            }
            case 'getWeekControl': {
              let re = `${this.showLang('com.ins.ch')}【K${cmd.channel}】`;
              for (let i = 1; i <= 7; i++) {
                for (let j = 0; j < 2; j++) {
                  let idx = (i - 1) * 2 + j + 1;
                  let enable = cmd.content[`enable${idx}`] ? this.showLang('com.state.enable') : this.showLang('com.state.disable');
                  re += `, ${this.weeks1[i]}${this.ampm[j]}${enable}`
                }
              }
              return re;
            }
            case 'getclock': return `${this.showLang('com.ins.success')}：${cmd.content.nowTime}`;
            case 'handControl': {
              let r = `${this.showLang('com.ins.success')}：${cmd.args.open ? this.showLang('com.but.open.lamp') : this.showLang('com.but.close.lamp')}`;
              if (cmd.devType != 'st6036') {
                r += ` ${this.showLang('com.date.to')}${this.showLang('com.date.time')} ${cmd.args.expire}`;
              }
              return r;
            }
            case 'getYearPlan': {
              if (cmd.args.month != cmd.content.month) {
                return `${this.showLang('com.ins.success.WrongMonth', cmd.args.month)}${cmd.content.month}`;
              } else {
                let strs = [];
                let mode = cmd.content.mode;
                let obj = cmd.content[`day${cmd.args.day}`];
                if (mode == 4) { //北京模式
                  for (let i = 1; i <= 4; i++) {
                    let ao = this.powerTableActions[obj[`a${i}`]];
                    let to = obj[`o${i}`];
                    // let ac = this.powerTableActions[obj[`ac${i}`]];
                    let tc = obj[`c${i}`];
                    strs.push(`${to}-${tc}:${ao}`);
                  }
                } else if (mode == 5) { //集中器模式
                  for (let i = 1; i <= 6; i++) {
                    let a = obj[`a${i}`]; //this.monitorActionTypes[];
                    let t = obj[`t${i}`];
                    let v = this.monitorActionTypes[a]; //a == 1 ? '' : obj[`v${i}`];
                    if (a != 1) {
                      v += ` - ${obj[`v${i}`]}`
                    }
                    strs.push(`${t} - ${v}`);
                  }
                } else {
                  for (let i = 1; i <= 4; i++) {
                    let a = this.powerTableActions[obj[`a${i}`]];
                    let t = obj[`t${i}`];
                    strs.push(`${t} - ${a}`);
                  }
                }
                return `${this.showLang('com.ins.success')}：${cmd.args.month}${this.showLang('com.date.month')}${cmd.args.day}${this.showLang('com.date.sun')}${this.showLang('com.date.plan')}：${strs.join(', ')}`
              }
            }
            case 'getComArgs': {
              // console.log('get com args', cmd)
              return `${this.showLang('com.but.dev.address.code')}: ${cmd.content.address}, ${this.showLang('com.BAUD')}: ${cmd.content.buad}, ${this.showLang('com.but.serial.port')}: ${cmd.content.parity}`;
            }
            default: return this.showLang('com.ins.success');

          }
      }
    },
    sendCommand: function(cmd){
      if(cmd.list.length == 0)return;
      this.$set(this, 'cmdResults', {});
      this.$axios.post(`//${this.domains.trans}/station/command/SendEnergyOld`, cmd).then(res => {
        if (res.code == 0) {
          // console.log('send cmd result', res.data, this.list);
          for (let item of res.data.list) {
            let ds = this.devices.filter(p => p.id == item.id);
            console.log('send cmd result', ds)
            if (ds.length == 0) continue;
            this.$set(ds[0], 'cmd', cmd.code);
            this.$set(ds[0], 'args', cmd.args);
            this.$set(ds[0], 'cmdId', item.success ? item.message : '');
            this.$set(ds[0], 'status', item.success ? 1 : 8);
            this.$set(ds[0], 'content', {});
            this.$set(ds[0], 'message', item.success ? '指令已进入发送队列' : item.message);
            // this.spanData();
          }
        }
      });
    },
  }
}
</script>
<style scoped>
.station-alarm-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /* padding-right: 40px; */
  /* border: solid 1px red; */
}
.alarm-data-query {
  height: 40px;
  flex: none;
  background-color: white;
  margin: 3px 1px;
  display: flex;
  align-items: center;
  padding: 10px;
  white-space: nowrap;
}
.alarm-data-area {
  /* border: solid 1px red; */
  height: 100px;
  flex: auto;
  border-radius: 6px;
}
.tab-active {
  border: solid 1px rgb(135, 159, 224);
  background-color: rgb(135, 159, 224);
  color: white;
}
.station-alarm-tabcontent {
  height: 500px;
  flex: auto;
  /* border: solid 1px red; */
}
/* .box-body{
  height: 0;
  flex: auto;
  margin-top: 5px;
  border: solid 1px red;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}
.box-body::-webkit-scrollbar {
  display: none;
} */
</style>